let body = document.querySelector('body')
let burger = document.querySelector('.burger')
let burgerClose = document.querySelector('.burger-close')
let menu = document.querySelector('.menu')
let headerOverlay = document.querySelector('.header__overlay')

burger & burger.addEventListener('click', () => {
  body.classList.add('no-scroll')
  headerOverlay.classList.add('active')
  menu.classList.add('active')
})

burgerClose & burgerClose.addEventListener('click', () => {
  body.classList.remove('no-scroll')
  headerOverlay.classList.remove('active')
  menu.classList.remove('active')
})

headerOverlay & headerOverlay.addEventListener('click', function(e) {
  if(e.target.className === headerOverlay.className) {
    body.classList.remove('no-scroll')
    headerOverlay.classList.remove('active')
    menu.classList.remove('active')
  }
})